import React, { useEffect, useState } from 'react'
import style from './style.module.css'
import { Box, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar, useMediaQuery } from '@mui/material'
import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import { useNavigate } from 'react-router-dom';
// import { MdSpaceDashboard } from "react-icons/md";
import { MdOutlineFormatListBulleted } from "react-icons/md";
import { MdPayments } from "react-icons/md";
import { FaUsersCog } from "react-icons/fa";
import { FiSettings } from "react-icons/fi";
import User from '../User/User';
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";

import { HiUserGroup } from "react-icons/hi2";

import { VscBell } from "react-icons/vsc";
import { PiCoins } from "react-icons/pi";
import { TbUsers } from "react-icons/tb";
import { TfiLayoutGrid2 } from "react-icons/tfi";
import { PiVideo } from "react-icons/pi";
import { FaHome, FaBook, FaSignOutAlt } from 'react-icons/fa'; 


const drawerWidth = 260;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }),
);

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'center',
}));

export default function Sidebar({ children }) {
    const matches = useMediaQuery('(min-width:1199px)');
    const [open, setOpen] = useState(true)
    const [toggle1, setToggle1] = useState(false)
    const navigate = useNavigate()
    let location = window.location.href

    function activeRoute(res) {
        if (location.includes(res)) {
            return true
        } else {
            return false
        }
    }

    useEffect(() => {
        if (matches) {
            setOpen(true)
        } else {
            setOpen(false)
        }
    }, [matches])

    const handleLogout = () => {
       
        localStorage.removeItem('authToken'); 
        sessionStorage.clear(); 

       
        navigate('/login');
    };

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <AppBar sx={{ background: 'white' }} position="fixed" open={!matches ? false : open}>
                    <Toolbar className='d-flex justify-content-between align-items-center'>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={() => setOpen(!open)}
                            edge="start"
                            sx={{ mr: 2, mt: -1 }}
                        >
                            <i className="bi bi-list text-black fw-700"></i>
                        </IconButton>
                        <div className='pointer text-black pe-md-4 pe-sm-2 pe-0 mt-1' style={{ marginTop: '-10px' }}>
                            <User />
                        </div>
                    </Toolbar>
                </AppBar>
                <Drawer
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                        },
                    }}
                    variant={!matches ? 'temporary' : "persistent"}
                    open={open} onClose={() => setOpen(!open)}>
                    <DrawerHeader>
                        <img src='/images/Learn3Ride.png' alt='' className='w-75' />
                    </DrawerHeader>
                    <Divider />
                    <List>


                        <ListItem className='pb-0'>
                            <ListItemButton className={`text-black ${activeRoute('overview') ? 'activeTab' : ''} rounded`} onClick={() => navigate('/OverView')}>
                                <ListItemIcon>
                                    <TfiLayoutGrid2 className={`fs-5 ${activeRoute('overview') ? '' : 'text-black'}  `} />
                                </ListItemIcon>
                                <ListItemText primary={'Overview'} />
                            </ListItemButton>
                        </ListItem>




                        <ListItem className='pb-0'>
                            <ListItemButton className={`text-black ${activeRoute('userManagement') ? 'activeTabUser' : ''} rounded`} onClick={() => setToggle1(!toggle1)}>
                                <ListItemIcon>
                                    <TbUsers className={`fs-5 ${activeRoute('userManagement') ? '' : 'text-black'}  `} />
                                </ListItemIcon>
                                <ListItemText primary={<div className='w-100 d-flex align-items-center f-15'>User Management {toggle1 ? <IoIosArrowUp className='ms-2' /> : <IoIosArrowDown className='ms-2' />}</div>} />
                            </ListItemButton>
                        </ListItem>
                        <div className={`px-3 ps-5 ${toggle1 ? 'pt-2 ' : ''}`} style={{ height: toggle1 ? '110px' : '0px', overflow: 'hidden', transition: 'height 0.3s' }}>
                            <List className=''>
                                <ListItemButton className={`text-black ${activeRoute('allUsers') ? 'activeTab' : ''} rounded`} onClick={() => navigate('/userManagement/allUsers')}>
                                    <ListItemText primary={<div className='f-15'>All Users</div>} />
                                </ListItemButton>
                                <ListItemButton className={`text-black ${activeRoute('activeSubscribers') ? 'activeTab' : ''} rounded`} onClick={() => navigate('/userManagement/activeSubscribers')}>
                                    <ListItemText primary={<div className='f-15'>Active Subscribers</div>} />
                                </ListItemButton>
                            </List>
                        </div>
                    
                        <ListItem className='pb-0'>
                            <ListItemButton className={`text-black ${activeRoute('payments') ? 'activeTab' : ''} rounded`} onClick={() => navigate('/payments')}>
                                <ListItemIcon>
                                    < PiCoins className={`fs-5 ${activeRoute('payments') ? '' : 'text-black'}  `} />
                                </ListItemIcon>
                                <ListItemText primary={<div className='f-15'>Payments</div>} />
                            </ListItemButton>
                        </ListItem>
                        
                        <ListItem className='pb-0'>
                            <ListItemButton className={`text-black ${activeRoute('lessons') ? 'activeTab' : ''} rounded`} onClick={() => navigate('/lessons')}>
                                <ListItemIcon>
                                    <PiVideo className={`fs-5 ${activeRoute('lessons') ? '' : 'text-black'}  `} />
                                </ListItemIcon>
                                <ListItemText primary={<div className='f-15'>Lesssons</div>} />
                            </ListItemButton>
                        </ListItem>

                        <ListItem className='pb-0'>
                            <ListItemButton className={`text-black ${activeRoute('notifications') ? 'activeTab' : ''} rounded`} onClick={() => navigate('/notifications')}>
                                <ListItemIcon>
                                    <VscBell className={`fs-5 ${activeRoute('notifications') ? '' : 'text-black'}  `} />
                                </ListItemIcon>
                                <ListItemText primary={<div className='f-15'>Notifications</div>} />
                            </ListItemButton>
                        </ListItem>

                        <ListItem className='pb-0'>
                            <ListItemButton className={`text-black ${activeRoute('settings') ? 'activeTab' : ''} rounded`} onClick={() => navigate('/settings')}>
                                <ListItemIcon>
                                    <TfiLayoutGrid2 className={`fs-5 ${activeRoute('settings') ? '' : 'text-black'}  `} />
                                </ListItemIcon>
                                <ListItemText primary={<div className='f-15'>Settings</div>} />
                            </ListItemButton>
                        </ListItem>
                    </List>

                    <li className="logout-button">
                    <button onClick={handleLogout}>
                        <FaSignOutAlt /> Logout
                    </button>
                </li>
                </Drawer>
                <Main open={!matches ? true : open}>
                    <div className={style.mainContainer}>
                        <div className={style.topbar}></div>
                        <div className={`${style.content} p-md-4 p-sm-3 p-2`}>
                            {children}
                        </div>
                    </div>
                </Main>
            </Box>
        </>
    )
}
