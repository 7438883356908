import React, { useEffect } from 'react'
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'
import Sidebar from '../Compunents/Sidebar/Sidebar'

export default function LoginAuth() {
  let login = localStorage.getItem('userId')
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (login) {
      if(location.pathname === '/'){
        navigate('/userManagement/allUsers')
      }
    }
  }, [])
  return (
    <div>
      {login ? <Sidebar><Outlet /></Sidebar> : <Navigate to='/login' />}
    </div>
  )
}
