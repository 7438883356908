import React, { useEffect, useState } from 'react'
import { MenuItem, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'

import TableContainer from '../../Compunents/TableContainer/TableContainer'
import { getMatchingData } from '../../Firebase/CloudFirestore/GetData'
import Loader from '../../Compunents/Loader/Loader'
import { changeDateFormate, paginateData } from '../../Utils/Utils'
import Pagination from '../../Compunents/Pagination/Pagination'
import moment from 'moment'
import { FiFilter } from "react-icons/fi";
import DropDownMenu from '../../Compunents/DropDownMenu/DropDownMenu'
import StatusFilterModal from './StatusFilterModal'
import DateFilterModal from './DateFilterModal'
import { useNavigate } from 'react-router-dom'
import { Button, Modal, ModalBody } from 'reactstrap'
import { updateDocument } from '../../Firebase/CloudFirestore/UpdateData'
import { UserManagement } from './UserManagement.css'
import { RiDeleteBinLine } from "react-icons/ri";
import { IoIosSearch } from "react-icons/io";




export default function AllUsers() {
    const navigate = useNavigate()
    const [loader, setLoader] = useState(true)
    const [page, setPage] = useState(0);
    const rowsPerPage = 5
    const [data, setData] = useState([])
    const [allData, setAllData] = useState([])
    const [noData, setNoData] = useState(false)
    const [openFilterModal, setOpenFilterModal] = useState(false)
    const [openDateFilterModal, setOpenDateFilterModal] = useState(false)
    const [statusChangeData, setStatusChangeData] = useState('')
    const [docId, setDocId] = useState('')
    const [openCommentModal, setopenCommentModal] = useState(false)
    const [selectedUsers, setSelectedUsers] = useState([]);
    // const [usersData, setUsersData] = useState()
    const [users, setUsers] = useState([
        { id: 1, name: "Rhea Singh", email: "akshay.singh@gmail.com", mobileNumber: "+419999999999", experienceLevel: "Beginner", timeSpent: "36 Hr", skillsAchieved: 12 },
        { id: 2, name: "Ajay Yadav", email: "ajay.yadav@gmail.com", mobileNumber: "+419999999999", experienceLevel: "Intermediate", timeSpent: "36 Hr", skillsAchieved: 12 },
        { id: 3, name: "Aarav Yadav", email: "aarav.yadav@gmail.com", mobileNumber: "+419999999999", experienceLevel: "Professional", timeSpent: "36 Hr", skillsAchieved: 12 },
        { id: 4, name: "Nirav Sinha", email: "nirav.sinha@gmail.com", mobileNumber: "+419999999999", experienceLevel: "Intermediate", timeSpent: "36 Hr", skillsAchieved: 12 },
        { id: 5, name: "Vikas Tripathi", email: "vikas.tripathi@gmail.com", mobileNumber: "+419999999999", experienceLevel: "Intermediate", timeSpent: "36 Hr", skillsAchieved: 12 },
        { id: 6, name: "Advik Srivastava", email: "advik.srivastava@gmail.com", mobileNumber: "+419999999999", experienceLevel: "Beginner", timeSpent: "36 Hr", skillsAchieved: 12 },
    ]);
    // Function to handle deleting selected users
    const handleDeleteSelected = () => {
        const updatedUsers = users.filter(users => !selectedUsers.includes(users.id));
        setUsers(updatedUsers);
        setSelectedUsers([]); // Clear the selection after deletion
    };
    const handleSelectUsers = (id) => {
        if (selectedUsers.includes(id)) {
            setSelectedUsers(selectedUsers.filter(useId => useId !== id));
        } else {
            setSelectedUsers([...selectedUsers, id]);
        }
    };




    async function getAllData() {
        try {
            let res = await getMatchingData('users', 'userType', '==', 'AllUsers')
            if (res.length === 0) {
                setNoData(true)
            } else {
                setNoData(false)
            }
            setAllData(res)
            let slicedData = paginateData(page, rowsPerPage, res)
            setData(slicedData)

        } catch (err) {
            console.log(err)
        } finally {
            setLoader(false)
        }

    }



    useEffect(() => {
        getAllData()
    }, [])



    useEffect(() => {
        let slicedData = paginateData(page, rowsPerPage, allData)
        setData(slicedData)
    }, [page, rowsPerPage])



    //   function filterByStatus(data) {
    //     let arr = [...allData]
    //     let newArr = arr?.filter(res => res?.status == data?.status)
    //     let slicedData = paginateData(page, rowsPerPage, newArr)
    //     setData(slicedData)
    //     if (slicedData.length == 0) {
    //       setNoData(true)
    //     } else {
    //       setNoData(false)
    //     }
    //     setOpenFilterModal(false)
    //   }

    //   function filterByDate(data) {
    //     let arr = [...allData]
    //     let newArr = arr?.filter(res => moment(changeDateFormate(res?.createdAt)).format('YYYY-MM-DD') == data?.date)
    //     let slicedData = paginateData(page, rowsPerPage, newArr)
    //     setData(slicedData)
    //     if (slicedData.length == 0) {
    //       setNoData(true)
    //     } else {
    //       setNoData(false)
    //     }
    //     setOpenDateFilterModal(false)
    //   }

    //   function clearFilter() {
    //     let slicedData = paginateData(page, rowsPerPage, allData)
    //     setData(slicedData)
    //     if (slicedData.length == 0) {
    //       setNoData(true)
    //     } else {
    //       setNoData(false)
    //     }

    //   }

    function searchData(e) {
        let value = e?.toLowerCase()
        let arr = [...allData]
        if (e == '') {
            let slicedData = paginateData(page, rowsPerPage, allData)
            setData(slicedData)
        } else {
            let newArr = arr?.filter(users => users?.owner_name?.toLowerCase()?.includes(value) || users?.Name?.toLowerCase()?.includes(value))
            let slicedData = paginateData(page, rowsPerPage, newArr)
            setData(slicedData)
            if (slicedData.length == 0) {
                setNoData(true)
            } else {
                setNoData(false)
            }
        }

    }


    function changeStatus(e, id) {
        setDocId(id)
        setStatusChangeData(e)
        setopenCommentModal(true)

    }

    async function updateStatus() {
        setLoader(true)
        try {
            await updateDocument('users', docId, { status: statusChangeData })

        } catch (err) {
            console.log(err)
        } finally {
            await getAllData()
            setLoader(false)
            setopenCommentModal(false)
            setDocId('')
        }
    }


    function statusChange(key) {
        switch (key) {
            case 'active':
                return 'text-success'
                break;
            case 'inactive':
                return 'text-danger'
                break;
            case 'suspended':
                return 'text-warning'
                break;

            default:
                break;
        }
    }


    return (


        <>



            {/* <Loader open={loader} /> */}
            <h2 className='fw-bold'>All  Users</h2>

            <div className=' search-delete row justify-content-md-between justify-content-sm-between justify-content-md-start mt-3'>

                <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 py-3'>


                    <input type='search' className=' form-control w-100 ' placeholder='Search Users' onChange={(e) => {
                        if (e.target.value == ' ') {
                            e.target.value = ''
                        } else {
                            searchData(e.target.value)
                        }
                    }} />
                </div>


                <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 py-3 col-12 d-flex justify-content-end mt-md-0 mt-sm-0 mt-2'>
                    <button className='btn border  '
                        onClick={handleDeleteSelected}
                    >< RiDeleteBinLine /> Delete</button>


                </div>


            </div>




            <div className='mt-4'>
                <TableContainer>
                    <Table >
                        {noData ? <caption className='text-center'>No Data Found</caption> : ''}
                        <TableHead >
                            <TableRow>


                                <TableCell >Name</TableCell>

                                <TableCell>Email</TableCell>

                                <TableCell>Mobile Number</TableCell>

                                <TableCell>Experience Level</TableCell>

                                <TableCell>Time Spent</TableCell>

                                <TableCell>Skills Achieved</TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users?.map((users, index) => {
                                return (
                                    <TableRow key={users.id}
                                        style={{ backgroundColor: index % 2 === 0 ? '#ffffff' : '#F7F8F9' }}
                                    >

                                        <TableCell
                                            className='pointer text-capitalize py-4' >
                                            <span className='pe-2'>
                                                <input

                                                    type="checkbox"
                                                    checked={selectedUsers.includes(users.id)}
                                                    onChange={() => handleSelectUsers(users.id)}
                                                />
                                            </span>
                                            {users?.name}</TableCell>

                                        <TableCell>{users?.email}</TableCell>

                                        <TableCell>{users?.mobileNumber}</TableCell>

                                        <TableCell>{users?.experienceLevel}</TableCell>

                                        <TableCell>{users?.timeSpent}</TableCell>

                                        <TableCell>{users?.skillsAchieved}</TableCell>


                                    </TableRow>
                                )
                            })}
                        </TableBody>

                    </Table>
                    <Pagination
                        allData={allData}
                        currentPage={page}
                        paginate={(d) => {
                            setPage(d);
                        }}
                        itemPerPage={rowsPerPage}
                    />

                </TableContainer>
            </div>


        </>
    )
}

