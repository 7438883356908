import { MenuItem, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import React, { useEffect, useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import TableContainer from '../../Compunents/TableContainer/TableContainer'
import DropDownMenu from '../../Compunents/DropDownMenu/DropDownMenu';
import { FiFilter } from 'react-icons/fi';
import Pagination from '../../Compunents/Pagination/Pagination';
import CustomDrawer from '../../Compunents/CustomDrawer/CustomDrawer';
import { changeDateFormate, paginateData } from '../../Utils/Utils';
import moment from 'moment';
import DateFilterModal from '../UserManagement/DateFilterModal';
import { getCollectionData } from '../../Firebase/CloudFirestore/GetData';
import { updateDocument } from '../../Firebase/CloudFirestore/UpdateData';
import Loader from '../../Compunents/Loader/Loader';

import { useNavigate } from 'react-router-dom';

import { UserManagement } from './UserManagement.css'

export default function Payments() {
  const [userPermission, setUserPermission] = useState(['View Payment']);
  const [page, setPage] = useState(0);
  const rowsPerPage = 5;
  const [loader, setLoader] = useState(false)

  const [allData, setAllData] = useState();


  const [noData, setNoData] = useState(false);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [openDateFilterModal, setOpenDateFilterModal] = useState(false);
  const [TypeFilterModal, setTypeFilterModal] = useState(false);
  const [openJobTypeFilterModal, setOpenJobTypeFilterModal] = useState(false);
  const [statusChangeData, setStatusChangeData] = useState('');
  const [docId, setDocId] = useState('');
  const [openCommentModal, setopenCommentModal] = useState(false);
  const [rowmodal, setrowModal] = useState({ status: false, fullData: {} });

  const [paymentsData, setPaymentsData] = useState([
    { name: 'Rhea Singh', orderId: 'order_OZKJ6ZaNm0NaLq', date: '21/12/2024', amount: '$99', status: 'Successful' },
    { name: 'Ajay Yadav', orderId: 'order_OZKJ6ZaNm0NaLq', date: '21/12/2024', amount: '$99', status: 'Successful' },
    { name: 'Aarav Yadav', orderId: 'order_OZKJ6ZaNm0NaLq', date: '21/12/2024', amount: '$99', status: 'Successful' },
    { name: 'Nirav Sinha', orderId: 'order_OZKJ6ZaNm0NaLq', date: '21/12/2024', amount: '$99', status: 'Pending' },
    { name: 'Vikas Tripathi', orderId: 'order_OZKJ6ZaNm0NaLq', date: '21/12/2024', amount: '$99', status: 'Successful' },
    { name: 'Advik Srivastava', orderId: 'order_OZKJ6ZaNm0NaLq', date: '21/12/2024', amount: '$99', status: 'Failed' },
  ]);
  const [data, setData] = useState([paymentsData]);
  const navigate= useNavigate();
  const rowtoggle = (data) => {
    if (userPermission.includes("View Payment")) {
      if (true) {
        setrowModal({ status: !rowmodal.status, fullData: data });
      }
    }
  };


  async function getListingData() {
    setLoader(true);
    try {
      let res = await getCollectionData('payment');
      
      console.log(res,"payment*");
      setAllData(res);
      let slicedData = paginateData(page, rowsPerPage, res);
      setData(slicedData);
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
    }
  }
  async function getListingData() {
    setLoader(true);
    try {
      let res = await getCollectionData('payment');
      console.log(res, "Fetched payment data"); // Debugging line
      setAllData(res);
      let slicedData = paginateData(page, rowsPerPage, res);
      setData(slicedData);
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
    }
  }

  
  function changeStatus(e, id) {
    setDocId(id)
    setStatusChangeData(e)
    setopenCommentModal(true)

  }

  async function updateStatus() {
    setLoader(true)
    try {
      await updateDocument('payment', docId, { status: statusChangeData })

    } catch (err) {
      console.log(err)
    } finally {
      await getListingData()
      setLoader(false)
      setopenCommentModal(false)
      setDocId('')
    }
  }



  function searchData(e) {
    let value = e?.toLowerCase()
    let arr = [...allData]
    if (e == '') {
      let slicedData = paginateData(page, rowsPerPage, allData)
      setData(slicedData)
    } else {
      let newArr = arr?.filter(res => res?.data?.payer?.payer_info?.first_name?.toLowerCase()?.includes(value) ||  res?.data?.payer?.payer_info?.last_name?.toLowerCase()?.includes(value) || res?.data?.payer?.payment_method?.toLowerCase()?.includes(value))
      let slicedData = paginateData(page, rowsPerPage, newArr)
      setData(slicedData)
      if (slicedData.length == 0) {
        setNoData(true)
      } else {
        setNoData(false)
      }
    }

  }



  useEffect(() => {
    console.log('Fetching listing data...');
    getListingData()
  }, [])
  
  


  function statusColor(key) {
    switch(key) {
      case 'Successful':
        return 'text-success';
      case 'Pending':
        return 'text-warning';
      case 'Failed':
        return 'text-danger';
      default:
        return '';
    }
  }


  return (
    <>
     
      <h2 className='fw-bold'>Payment</h2>
      <div className='search-delete row justify-content-md-between justify-content-sm-between justify-content-md-start mt-3'>
        <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 py-3'>
          <input type='search' className='form-control w-100' placeholder='Search Users ' onChange={(e) => {
            if (e.target.value == ' ') {
              e.target.value = ''
            } else {
              searchData(e.target.value)
            }
          }} />
        </div>
      
      </div>

      <div className='mt-4'>
        <TableContainer>
          <Table>
            {noData ? <caption className='text-center'>No Data Found</caption> : ''}
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Order ID</TableCell>
                <TableCell>Date/Time</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paymentsData?.map((res, index) => {
                return (
                  <TableRow hover key={index}
                  style={{ backgroundColor: index % 2 === 0 ? '#ffffff' : '#F7F8F9' }}
                   >
                   <TableCell onClick={() => navigate(`paymentDetailsView/`)}>{res?.name}</TableCell>
                    <TableCell>{res?.orderId}</TableCell>

                    <TableCell>{moment(res?.data?.create_time).format('YYYY-MM-DD HH:mm:ss')}</TableCell>

                    <TableCell>{res?.amount}</TableCell>
                            
                    <TableCell className={statusColor(res?.status)}>{res?.status}</TableCell>
                   
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
          <Pagination
            allData={allData}
            currentPage={page}
            paginate={(d) => setPage(d)}
            itemPerPage={rowsPerPage}
          />
        </TableContainer>
      </div>
    </>
  );
}
