import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { getCollectionData } from '../../Firebase/CloudFirestore/GetData'
import { changeDateFormate } from '../../Utils/Utils'
import { IoIosArrowBack } from "react-icons/io";

const PaymentDetailsView = ({ activeTab, setLoader }) => {
    let { id } = useParams()
    const [allData, setAllData] = useState([])
    const [phoneNo, setPhoneNo] = useState()


    async function getallData() {
        setLoader(true)
        try {
            let [res] = await getCollectionData('payment')
            console.log(res, "///////////////////////////////////")
            if (res !== undefined) {
                setAllData(res)
            }
        } catch (err) {
            console.log(err)
        } finally {
            setLoader(false)
        }
    }

    useEffect(() => {
        if (activeTab === '0') {
            getallData()
        }
    }, [activeTab])
    async function getUserData() {
        setLoader(true)
        try {
            let [response] = await getCollectionData('users', "userId", '==', 'id')
            console.log(response.phoneNumber, "///////////////////////////////////")
            if (response !== undefined) {
                setPhoneNo(response.phoneNumber)
            }
        } catch (err) {
            console.log(err)
        } finally {
            setLoader(false)
        }
    }

    useEffect(() => {
        if (activeTab === '0') {
            getUserData()
        }
    }, [activeTab])

    const payerInfo = allData?.data?.payer?.payer_info || {}
    const transactions = allData?.data?.transactions || []
    const transactionAmount = transactions.map(e => e.amount.total).join(', ')
    const formattedDate = moment(allData?.data?.create_time).format('YYYY-MM-DD HH:mm:ss')

    return (
        <>
            <div className='border rounded p-3 mt-3'>
                <div className='d-flex justify-content-between align-items-center'>


                      <IoIosArrowBack onClick={() => window.history.back()} className='px-2'/>
                    <div className='fs-5 fw-600 text-secondary'>Payment Details</div>
                    
                </div>
                <div className={`mt-4`}>

                    <div className='border rounded p-3 mt-3'>
                        <div className='fs-5 fw-600 text-secondary'>User Details</div>
                        <div className="row">
                            <div className="col-md-6 mt-3">
                                <label className="f-16">Name</label>
                                <input
                                    className='form-control custom-input'
                                    disabled
                                    type="text"
                                // value={name}
                                />
                            </div>

                            <div className="col-md-6 mt-3">
                                <label className="f-16">Phone No</label>
                                <input
                                    className='form-control custom-input'
                                    disabled
                                    type="text"
                                // value={phoneNo}
                                />
                            </div>
                            <div className="col-md-6 mt-3">
                                <label className="f-16">Email Id</label>
                                <input
                                    className='form-control custom-input'
                                    disabled
                                    type="text"
                                // value={payerInfo?.email}
                                />
                            </div>
                            <div className="col-md-6 mt-3">
                                <label className="f-16">Experience Level</label>
                                <input
                                    className='form-control custom-input'
                                    disabled
                                    type="text"
                                // value={experienceLevel}
                                />
                            </div>

                            <div className='fs-5 fw-600 text-secondary mt-4'>Other Details</div>

                            <div className="col-md-6 mt-3">
                                <label className="f-16">Order Id</label>
                                <input
                                    className='form-control custom-input'
                                    disabled
                                    type="text"
                                // value={allData?.orderId}
                                />
                            </div>
                            <div className="col-md-6 mt-3">
                                <label className="f-16">Amount</label>
                                <input
                                    className='form-control custom-input'
                                    disabled
                                    type="text"
                                // value={amount}
                                />
                            </div>

                            <div className="col-md-6 mt-3">
                                <label className="f-16">Payment Status</label>
                                <input
                                    className='form-control custom-input'
                                    disabled
                                    type="text"
                                // value={allData?.status}
                                />
                            </div>
                            <div className="col-md-6 mt-3">
                                <label className="f-16">Payment Date</label>
                                <input
                                    className='form-control custom-input'
                                    disabled
                                    type="text"
                                    value={formattedDate}
                                />
                            </div>
                            <div className="col-md-6 mt-3">
                                <label className="f-16">Payment Id</label>
                                <input
                                    className='form-control custom-input'
                                    disabled
                                    type="text"
                                // value={paymentId}
                                />
                            </div>
                            <div className="col-md-6 mt-3">
                                <label className="f-16">Payment Order Id</label>
                                <input
                                    className='form-control custom-input'
                                    disabled
                                    type="text"
                                // value={paymentOrderId}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PaymentDetailsView
