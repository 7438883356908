import { getAuth } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

// const firebaseConfig = {
//   apiKey: "AIzaSyAuqEBbxNMAC9pmkXtAqJxxDRl2ccodQ-E",
//   authDomain: "linkease-39929.firebaseapp.com",
//   projectId: "linkease-39929",
//   storageBucket: "linkease-39929.appspot.com",
//   messagingSenderId: "532906358529",
//   appId: "1:532906358529:web:4d9d5b15bd7d29699f4d00"
// };

const firebaseConfig = {
  apiKey: "AIzaSyBYJKW00gArJFK_6s0MaxBbeefCe-Fnalw",
  authDomain: "learn-3-ride.firebaseapp.com",
  projectId: "learn-3-ride",
  storageBucket: "learn-3-ride.appspot.com",
  messagingSenderId: "1061663728379",
  appId: "1:1061663728379:web:9d97849aa6b6d87253065e"
};

const app = initializeApp(firebaseConfig);

export const storage = getStorage(app);
export const db = getFirestore(app);
export const auth = getAuth(app);
