import React, { useState } from 'react';
import './AddTutorials.css'; // Import the CSS
import { MdArrowBackIosNew } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import { IoCloudUploadOutline } from "react-icons/io5";
import { MdOutlineFileUpload } from "react-icons/md";

const AddTutorials = () => {
    const [tutorialName, setTutorialName] = useState('');
    const [thumbnail, setThumbnail] = useState(null);
    const [media, setMedia] = useState(null);
    const [description, setDescription] = useState('');
    const navigate = useNavigate();

    // Handle form submission
    const handleSubmit = (event) => {
        event.preventDefault();
        // Perform the submit logic here
        console.log({
            tutorialName,
            thumbnail,
            description,

        });
    };

    const handleThumbnailUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            setThumbnail(file.name);
        }
    };

    const handleMediaUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            setMedia(file.name);
        }
    };
    const handleAddTutorial = () => {
        navigate("/addTutorials");
    };
    const goBack = () => {
        navigate(-1);
    };

    return (
        <div className="add-lesson-container">
            <div className="header">
                <MdArrowBackIosNew onClick={goBack} color="secondary" className="back-button" />
                <h4>Add Lessons</h4>
            </div>
            <form onSubmit={handleSubmit} className="lesson-form">
                {/* Lesson Name */}
                <div className="form-group">
                    <label htmlFor="tutoriallName">Lesson Name</label>
                    <input
                        type="text"
                        id="tutorialName"
                        value={tutorialName}
                        onChange={(e) => setTutorialName(e.target.value)}
                        placeholder="Enter Lesson Name"
                        required
                    />
                </div>

                {/* Tutorial Thumbnail Upload */}
                <div className="form-group">
                    <label>Tutorial Thumbnail:</label>
                    <div className="thumbnail-upload">
                        <label htmlFor="upload-thumbnail" className="upload-btn">
                        <MdOutlineFileUpload  className='fs-5' /> Upload Image
                        </label>
                        <input
                            type="file"
                            accept="image/*"
                            id="upload-thumbnail"
                            className="upload-input"
                            onChange={handleThumbnailUpload}
                        />
                        {thumbnail && (
                            <div className="uploaded-file">
                                <span>{thumbnail}</span>
                                <button onClick={() => setThumbnail(null)}>X</button>
                            </div>
                        )}
                    </div>
                </div>

                {/* Lesson Description */}
                <div className="form-group">
                    <label htmlFor="description">Lesson Description</label>
                    <textarea
                        id="description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder="Enter Tutorial Description"
                        rows="5"
                        required
                    ></textarea>
                </div>


                {/* Media Upload */}
                <div className="form-group">
                    <label>Upload Media</label>
                    <div className="media-upload">
                        <IoCloudUploadOutline />
                        <p>Browse and choose the video you want to upload from your computer</p>
                        <label htmlFor="upload-media" className="upload-btn">
                            +
                        </label>
                        <input
                            type="file"
                            accept="video/*"
                            id="upload-media"
                            className="upload-input"
                            onChange={handleMediaUpload}
                        />
                        {media && <span className="uploaded-file">{media}</span>}
                    </div>

                </div>

                {/* Buttons */}
                <div className="form-actions">
                    <button type="button" className="cancel-button" onClick={goBack}>Cancel</button>
                    <button type="submit" className="add-button" onClick={handleAddTutorial}>Add Tutorials</button>
                </div>
            </form>
        </div>
    );
}

export default AddTutorials;
