import React from 'react';
import { Button } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import './LessonsDetails.css';
import { MdArrowBackIosNew } from "react-icons/md";

const LessonsDetails = () => {
    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1);
    };

    return (
        <div>
        <div className="lesson-details-container">
            <div className="header">
                <MdArrowBackIosNew onClick={goBack} color="secondary" className="back-button" />
                <h4>Lesson Details</h4>
            </div>

            <div className="lesson-content">
                <div className="lesson-info">
                    <div className="lesson-image">
                        <img src="" alt="Lesson" />
                    </div>
                    <div className="lesson-description">
                        <h4>Balancing Basic to Advance</h4>
                        <span className="level">Beginner Level</span>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                            Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                        </p>
                    </div>
                </div>

                <div className="tutorials-section">
                    <h4>Tutorials</h4>
                    <div className="tutorials">
                        <div className="tutorial-card">
                            <img src="./tutorial-image.png" alt="Tutorial 1" />
                            <p>Learn to Balance & Steer</p>
                        </div>
                        <div className="tutorial-card">
                            <img src="./tutorial-image.png" alt="Tutorial 2" />
                            <p>Learn to Balance & Steer</p>
                        </div>
                    </div>
                </div>

                <div className="actions">
                    <Button color="secondary" onClick={goBack}>Cancel</Button>
                    <Button color="primary">Edit Lesson</Button>
                </div>
            </div>
        </div>
        </div>
    );
};

export default LessonsDetails;
