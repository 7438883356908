import './App.css';

import { Route, Routes } from 'react-router-dom';
import LoginAuth from './Auth/LoginAuth';
import Login from './Pages/Authentication/Login';

import AllUsers from './Pages/UserManagement/AllUsers';
import ActiveSubscribers from './Pages/UserManagement/ActiveSubscribers';

import Payments from './Pages/Payments/Payments';
import PaymentDetailsView from './Pages/Payments/PaymentDetailsView';

import Lessons from './Pages/Lessons/Lessons'
import LessonsDetails from './Pages/Lessons/LessonsDetails'
import AddLessons from './Pages/Lessons/AddLessons'
import AddTutorials from './Pages/Lessons/AddTutorials'

import Notifications from './Pages/Notification/Notifications';



function App() {
  return (
    <>
      <Routes>
        <Route path='/login' element={<Login />} />

        <Route path='/' element={<LoginAuth />}>

          <Route path='/userManagement/allUsers' element={<AllUsers />} />

          <Route path='/userManagement/activeSubscribers' element={<ActiveSubscribers />} />

          <Route path='/payments' element={<Payments />} />
          <Route path='/payments/paymentDetailsView' element={<PaymentDetailsView />} />

          <Route path='/lessons' element={<Lessons />} />
          <Route path='/lessonsDetails' element={<LessonsDetails />} />
          <Route path='/addLessons' element={<AddLessons />} />
          <Route path='/addTutorials' element={<AddTutorials />} />
          

          <Route path='/notifications' element={<Notifications />} />

          {/* <Route path='/settings' element={<Settings />} /> */}
          
        </Route>
      </Routes>
    </>
  );
}

export default App;
