import React, { useState } from "react";
import { AddLessons } from './AddLessons.css'
import { useNavigate } from 'react-router-dom';
import { MdArrowBackIosNew } from "react-icons/md";
import { MdOutlineFileUpload } from "react-icons/md";

const AddLesson = () => {
  // State variables for the form fields
  const [lessonName, setLessonName] = useState("");
  const [thumbnail, setThumbnail] = useState(null);
  const [lessonLevel, setLessonLevel] = useState("");
  const [description, setDescription] = useState("");
  const navigate = useNavigate();

  // Handle thumbnail upload
  const handleThumbnailUpload = (event) => {
    setThumbnail(event.target.files[0]);
  };

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    // Perform the submit logic here
    console.log({
      lessonName,
      thumbnail,
      lessonLevel,
      description,
    });
  };
  const goBack = () => {
    navigate(-1);
};
const handleAddTutorial = () => {
  navigate("/addTutorials");
};

  return (
    <div className="add-lesson-container">
       <div className="header">
                <MdArrowBackIosNew onClick={goBack} color="secondary" className="back-button" />
                <h4>Add Lessons</h4>
            </div>
      <form onSubmit={handleSubmit} className="lesson-form">
        {/* Lesson Name */}
        <div className="form-group">
          <label htmlFor="lessonName">Lesson Name</label>
          <input
            type="text"
            id="lessonName"
            value={lessonName}
            onChange={(e) => setLessonName(e.target.value)}
            placeholder="Enter Lesson Name"
            required
          />
        </div>

        {/* Lesson Thumbnail */}
        <div className="form-group">
          <label htmlFor="thumbnail">Lesson Thumbnail</label>
          <input type="file" id="thumbnail" onChange={handleThumbnailUpload} />
          {thumbnail && <p>{thumbnail.name}</p>}
        </div>

        {/* Lesson Level */}
        <div className="form-group">
          <label htmlFor="lessonLevel">Lesson Level</label>
          <select
            id="lessonLevel"
            value={lessonLevel}
            onChange={(e) => setLessonLevel(e.target.value)}
            required
          >
            <option value="">Select Lesson Level</option>
            <option value="beginner">Beginner</option>
            <option value="intermediate">Intermediate</option>
            <option value="advanced">Advanced</option>
          </select>
        </div>

        {/* Lesson Description */}
        <div className="form-group">
          <label htmlFor="description">Lesson Description</label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Enter Lesson Description"
            rows="5"
            required
          ></textarea>
        </div>

        {/* Buttons */}
        <div className="form-actions">
          <button type="button" className="cancel-button"  onClick={goBack}>Cancel</button>
          <button type="submit" className="add-button"  onClick={handleAddTutorial}>Add Tutorials</button>
        </div>
      </form>
    </div>
  );
};

export default AddLesson;

